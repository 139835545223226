.overlayclass {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 9999998;
  }
  
  .CookieConsent {
    max-width: 500px;
    border-radius: 50px;
    padding: 20px 30px;
    color: white;
    background-color: rgba(0, 0, 0, 0.8);
    position: fixed;
    z-index: 9999999;
    top: 50%;
    left: 50%;
    right: auto;
    bottom: auto;
    transform: translate3d(-50%, -50%, 9999990px);
    width: 95%;
    box-shadow: 0 3px 10px rgba(0, 0, 0, 0.1);
    animation: fadeIn 1s;
  }
  
  .CookieConsent button {
    margin: auto;
    padding: 12px 24px;
    font-size: 16px;
    border-radius: 50px;
    cursor: pointer;
  }
  
  .CookieConsent button:first-child {
    background-color: red;
    color: white;
    margin-right: 10px;
  }
  
  .CookieConsent button:last-child {
    background-color: green;
    color: white;
  }
  
  .CookieConsent button:hover {
    opacity: 0.8;
  }